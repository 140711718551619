<template>
  <div class="content">
    <h1>Authorization</h1>

    <p>API requests may be authorized in one of two ways:</p>
    <ul>
      <li>Include your API key in the header (recommended)</li>
      <li>Include your API key as a query parameter</li>
    </ul>

    <br />

    <h5>Authorization Header Example (Recommended)</h5>

    <p>
      Including your key in the <span class="value">Authorization</span> header is the
      recommended approach, as it will work for all request types, and it will eliminate
      the need to include your key as a query parameter or object property. Here is an
      example using the <a href="https://github.com/axios/axios">Axios</a> HTTP library to
      set the header:
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      const response = await axios.get({
      <div style="padding-left: 18px">
        url: 'https://api.taxid.pro/validate?country=de&amp;tin=365742618098',
        <br />
        headers: { 'Authorization': <span class="has-text-warning">YOUR_API_KEY</span> }

        <br />
      </div>
      })
    </div>
    <br />
    <h5>Query Parameter Example</h5>
    <p>
      Your key may be included as a query parameter on any request. Here is an example of
      a <router-link :to="{ name: 'Validation' }">Validation</router-link> request using a
      query parameter to set the API key:
    </p>
    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      <span class="has-text-success">GET</span> https://api.taxid.pro/validate<br />
      <div style="padding-left: 18px">
        ? key = <span class="has-text-warning">YOUR_API_KEY</span> <br />
        &amp; tin = 365742618098<br />
        &amp; country = de<br />
        &amp; type = individual<br />
      </div>
    </div>
    <br />
  </div>
</template>

<style scoped>
.param {
  font-family: monospace;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
.param-note {
  font-family: open-sans, sans-serif;
  font-weight: bold;
  color: #aaa;
  font-size: 0.75rem;
}
.value {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #f5f5f5;
  font-size: 0.75rem;
  padding: 2px 4px 1px;
  font-family: monospace;
}
</style>

<script>
export default {
  name: 'DownloadPdf',
  mounted() {
    if (this.$route.hash) location.hash = this.$route.hash
    else window.scrollTo(0, 0)
  },
}
</script>